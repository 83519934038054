import { Colours } from '../Colours';
import { NewP1 } from '../GeneralComponents';
import { UserInfo } from '../../models/User';
import {
  findAvailableVideoLengthByProductName,
  findMaxSlideshowLengthByProductName,
  findMaxVideoLengthByProductName,
  findPlanNameByAppSumoProductId,
  findPlanNameByProductId
} from '../../utils/premium';
import { gaPremiumPurchase } from '../../lib/GoogleAnalytics';
import { useRouter } from 'next/router';
import Email from '../../models/Email';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
const mic = require('../../assets/images/landing/mic.svg');
const slideshow = require('../../assets/images/landing/slideshow.svg');

const NewPlanInformationPill = ({ user }: { user: UserInfo }): JSX.Element => {
  const premium = user?.current_premium;
  const permission = user?.premium_permissions;
  const router = useRouter();
  const [planName, setPlanName] = useState('unknown');
  const [audiogramCredits, setAudiogramCredits] = useState<
    number | 'unlimited'
  >(0);
  const [slideshowCredits, setSlideshowCredits] = useState<
    number | 'unlimited'
  >(0);

  const justPaid = () => {
    if (
      router.query &&
      router.query.payment &&
      router.query.payment === 'success'
    )
      return true;
    return false;
  };

  useEffect(() => {
    if (justPaid()) {
      const paidPlan = router.query.plan as string;
      setPlanName(paidPlan);
      gaPremiumPurchase(
        paidPlan,
        premium?.source === 'stripe' && premium.id ? premium.id : null
      );
      return;
    }
    if (premium) {
      if (premium.source === 'appsumo') {
        setPlanName(findPlanNameByAppSumoProductId(premium.plan_name));
      } else {
        setPlanName(findPlanNameByProductId(premium.product_id));
      }
      return;
    }
    setPlanName('free');
  }, [premium, router.query]);

  useEffect(() => {
    if (justPaid()) {
      const paidPlan = router.query.plan as string;
      if (!paidPlan) return;
      setSlideshowCredits(
        findMaxSlideshowLengthByProductName(paidPlan.toLowerCase()) / 60
      );
      setAudiogramCredits(
        findMaxVideoLengthByProductName(paidPlan.toLowerCase()) === 'unlimited'
          ? 'unlimited'
          : (findMaxVideoLengthByProductName(
              paidPlan.toLowerCase()
            ) as number) / 60
      );
      Email.sendPurchaseSuccessEmail(user);
      return;
    }
    if (permission) {
      if (permission.remaining_video_seconds) {
        setAudiogramCredits(
          findAvailableVideoLengthByProductName(
            Math.floor(permission.remaining_video_seconds / 60),
            premium?.plan_name
          )
        );
      }
      if (permission.remaining_transcription_seconds)
        setSlideshowCredits(
          Math.floor(permission.remaining_transcription_seconds / 60)
        );
      return;
    }
    setAudiogramCredits(30);
    setSlideshowCredits(10);
  }, [permission, router.query]);

  return (
    <div
      style={{
        backgroundColor: 'rgba(249, 250, 251, 1)'
        // padding: '8px 16px 8px 16px',
        // display: 'flex',
        // flexDirection: 'column',
        // borderWidth: 1,
        // borderColor: 'rgba(234, 236, 240, 1)',
        // gap: 10,
        // height: '100%'
      }}
      className="flex flex-col gap-[10px] rounded-lg border border-solid border-gray-200 px-4 py-[10px]"
    >
      <div className="flex flex-col">
        <div className="text-xs font-500 text-grey-400">Plan</div>
        <NewP1 className="capitalize">{planName}</NewP1>
      </div>
      <div className="flex flex-row">
        <div className="flex items-center">
          <Image alt="mic" loading={'lazy'} src={mic} />
        </div>
        <div className="ml-3 flex flex-col self-center text-left">
          <div className="text-xs font-500 text-grey-400">
            Audiogram video left
          </div>
          <NewP1>
            {audiogramCredits === 'unlimited'
              ? 'Unlimited'
              : `${audiogramCredits}mins`}
          </NewP1>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex items-center">
          <Image alt="slideshow" loading={'lazy'} src={slideshow} />
        </div>
        <div className="ml-3 flex flex-col self-center text-left">
          <div className="text-xs font-500 text-grey-400">AI video left</div>
          <NewP1>{slideshowCredits}mins</NewP1>
        </div>
      </div>
      <div className="mt-2 flex flex-row gap-3">
        <Link href={'/pricing'} className="text-xs font-500" target="_blank">
          View Plans
        </Link>
        <a
          href="/pricing"
          className="text-xs font-500 text-blue-600"
          target="_blank"
        >
          Upgrade
        </a>
      </div>
    </div>
  );
};
export default NewPlanInformationPill;
