import moment from 'moment';

// const GOOGLE_SERVICE_DEV_CLIENT_ID = '814873421385-7jut0r2j44jfucfgl76sippu81pcv41j.apps.googleusercontent.com';
// const GOOGLE_SERVICE_PROD_CLIENT_ID = '137786246442-42f0b31uro3c59m8gv5v313c1qddstih.apps.googleusercontent.com';

const IS_DEV_ENV =
  process.env && process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production';

const API_DEV =
  'https://6lbq5zyct8.execute-api.ap-southeast-1.amazonaws.com/dev';
const API_PROD =
  'https://4i20to0y70.execute-api.ap-southeast-1.amazonaws.com/production';

const API_URL = IS_DEV_ENV ? API_DEV : API_PROD;
const MEDIAS_LAMBDA_API_URL = IS_DEV_ENV
  ? 'https://adrtmjk6poyqwxcba577ob4pma0ttmek.lambda-url.ap-southeast-1.on.aws/apis'
  : // ? 'http://localhost:3000/dev/apis'
    'https://wujrvapgi36hd3siv6a4ius7gu0ksrpm.lambda-url.ap-southeast-1.on.aws/apis';
// const GOOGLE_SERVICE_CLIENT_ID = IS_DEV_ENV ? GOOGLE_SERVICE_DEV_CLIENT_ID : GOOGLE_SERVICE_PROD_CLIENT_ID;

const PROXY_URL = 'https://jpt-cors-anywhere-676bb7cb682d.herokuapp.com/';

const VIDEO_ROUTE = IS_DEV_ENV
  ? 'https://us-central1-jpt-dev-bd5c3.cloudfunctions.net/videoRoute/apis'
  : 'https://us-central1-jpt-prod.cloudfunctions.net/videoRoute/apis';

const UPLOAD_BUCKET = IS_DEV_ENV ? 'jupitrr-staging-playground' : 'jupitrr-ugc';

const MIXPANEL_KEY = IS_DEV_ENV
  ? 'e17e6e35081f4865d3fcae317acdab24'
  : '86ab2cba6d80ba1859ab83d8840f2d01';

const UNSPLASH_ACCESS_KEY = 'Nq6lm9V-GlGP92zU02aCh3naUJsKfWCyhXWm3KGzx_w';

//TODO: migrate this to backend for safe practice
const PEXELS_KEY_LIST = [
  't89U1XKMHqKxLV7CWe13AHIsuje9gw1A2AHf7G8gfZqJtz5cRHENu1Wm', //jerome@jupitrr
  'EecB3h4ILzMHNTA99tMSo8Zp3oxwtKy3aLeVxmbqYwzvBY2uJrmhsbEH', //Soul Kit
  'CKtsCpoQoSLPrGY4Z2nH0Q0qVFxknTJVhdFVLavB7nplfp1xSfn7dnmt' //Vamsi
];

const DEMO_VIDEO_URL =
  'https://www.loom.com/embed/cd2cdbb78cad48d79fb6a19802ef15e0?sid=84fa2bfd-62d7-4c16-8ca0-2649320bc56f';

const FREE_PLANS: {
  [key: string]: {
    priceId: string;
    productId: string;
    paymentLink: string;
    months: number;
    link: string;
  };
} = !IS_DEV_ENV
  ? {
      THREE_MONTHS_CREATOR: {
        priceId: 'price_1NsrRMInAv50s8RRhLdRGV1C',
        productId: 'prod_OO7zUw4co10Xa9',
        paymentLink: 'plink_1NssnLInAv50s8RRftaSIDVh',
        months: 3,
        link: 'https://buy.stripe.com/6oE7tXfp42YCcla28d'
      },
      ONE_MONTH_CREATOR: {
        priceId: 'price_1OHuhBInAv50s8RRITwemnFo',
        productId: 'prod_OO7zUw4co10Xa9',
        paymentLink: 'plink_1OHutbInAv50s8RRhyr2nchc',
        months: 1,
        link: 'https://buy.stripe.com/6oE7tXfp42YCcla28d'
      }
    }
  : {
      THREE_MONTHS_CREATOR: {
        priceId: 'price_1NssnyInAv50s8RR7H7LXMWp',
        productId: 'prod_OOAsOMp6BvQ3kY',
        paymentLink: 'plink_1NssoEInAv50s8RRaI7TImZN',
        months: 3,
        link: 'https://buy.stripe.com/test_6oEdTwaqD1IW14Q5kq'
      },
      ONE_MONTH_CREATOR: {
        priceId: 'price_1NssnyInAv50s8RR7H7LXMWp',
        productId: 'prod_OOAsOMp6BvQ3kY',
        paymentLink: 'plink_1NssoEInAv50s8RRaI7TImZN',
        months: 1,
        link: 'https://buy.stripe.com/test_6oEdTwaqD1IW14Q5kq'
      }
    };

const paymentInfo = IS_DEV_ENV
  ? {
      starter: {
        price: 'price_1P45TTInAv50s8RRYApIQLdp'
      },
      creator: {
        price: 'price_1NbOX5InAv50s8RRTRHy9MOA'
      },
      pro: {
        price: 'price_1NbOXTInAv50s8RRlpNkOO6r'
      }
    }
  : {
      starter: {
        price: 'price_1NmnISInAv50s8RRP9HGKkDj'
      },
      creator: {
        price: moment().isSameOrBefore(moment('2023-09-13T00:37:22.599Z'))
          ? 'price_1Nn4yUInAv50s8RRS45ekvud'
          : 'price_1NbLjdInAv50s8RR8T23gHrq'
      },
      pro: {
        price: 'price_1NbLlUInAv50s8RRmEcQvxgp'
      }
    };

interface PlanData {
  name: string;
  currency: string;
  price: string;
  priceInNumber: number;
  features: FeatureData[];
  mobileFeatures: string[];
  paymentInfo: {
    price: string;
  };
  textColor: string;
}

type FeatureType =
  | 'ai-video'
  | 'audiogram'
  | 'watermark'
  | 'extra-transcription'
  | 'language'
  | 'resolution'
  | 'stock'
  | 'file-size';

interface FeatureData {
  id: FeatureType;
  feature: string;
}

const AI_PLANS = ['creator', 'pro'];
const AUDIOGRAM_PLANS = ['free', 'starter'];
const PLANS = [...AI_PLANS, ...AUDIOGRAM_PLANS];
type PLAN_OPTIONS =
  | 'free'
  | 'starter'
  | 'creator'
  | 'pro'
  | 'jupitrr_tier1'
  | 'jupitrr_tier2'
  | 'jupitrr_tier3';

const plans: PlanData[] = [
  {
    name: 'Free',
    currency: '$',
    price: '0',
    priceInNumber: 0,
    features: [
      { id: 'ai-video', feature: '10mins / month' },
      { id: 'audiogram', feature: '30mins / month' },
      { id: 'file-size', feature: '300MB and 10mins per file' },
      { id: 'watermark', feature: 'With Jupitrr watermark' },
      { id: 'extra-transcription', feature: 'N/A' },
      { id: 'language', feature: '50+' },
      { id: 'resolution', feature: '1080 HD' },
      { id: 'stock', feature: 'Unlimited' }
    ],
    mobileFeatures: [
      'Up to 10 minutes of AI video transcription each month',
      '30 minutes per month of audiogram export limit',
      'Up to 10 minutes and 300MB per AI Video and audiogram',
      'With Jupitrr watermark',
      'No additional transcription hours available',
      '50+ languages supported',
      '1080 HD export',
      'Unlimited stock library'
    ],
    paymentInfo: null,
    textColor: 'bg-gradient-blue-green'
  },
  {
    name: 'Starter',
    price: '14',
    currency: '$',
    priceInNumber: 14,
    features: [
      { id: 'ai-video', feature: '10mins / month' },
      { id: 'audiogram', feature: '360mins / month' },
      { id: 'file-size', feature: '300MB and 30mins per file' },
      { id: 'watermark', feature: 'No watermark' },
      { id: 'extra-transcription', feature: 'N/A' },
      { id: 'language', feature: '50+' },
      { id: 'resolution', feature: '1080 HD' },
      { id: 'stock', feature: 'Unlimited' }
    ],
    mobileFeatures: [
      'Up to 10 minutes of AI video transcription each month',
      '360mins per month of audiogram export limit',
      'Up to 30 minutes and 300MB per AI Video and audiogram',
      'No restricted duration per audiogram',
      'No watermark',
      'No additional transcription hours available',
      '50+ languages supported',
      '1080 HD export',
      'Unlimited stock library'
    ],
    paymentInfo: paymentInfo['starter'],
    textColor: 'bg-gradient-orange'
  },
  {
    name: 'Creator',
    price: '19',
    currency: '$',
    priceInNumber: 16.99,
    features: [
      { id: 'ai-video', feature: '1200mins / month' },
      { id: 'audiogram', feature: 'Unlimited' },
      { id: 'file-size', feature: '300MB and 30mins per file' },
      { id: 'watermark', feature: 'No watermark' },
      { id: 'extra-transcription', feature: '$2 / hour' },
      { id: 'language', feature: '50+' },
      { id: 'resolution', feature: '1080 HD' },
      { id: 'stock', feature: 'Unlimited' }
    ],
    mobileFeatures: [
      'Up to 1200 minutes of AI video transcription each month',
      'No export limit for audiogram each month',
      'Up to 30 minutes and 300MB per AI Video and audiogram',
      'No watermark',
      '$2 / hour for additional transcription hours',
      '50+ languages supported',
      '1080 HD export',
      'Unlimited stock library'
    ],
    paymentInfo: paymentInfo['creator'],
    textColor: 'bg-gradient-yellow-purple'
  },
  {
    name: 'Pro',
    price: '39',
    currency: '$',
    priceInNumber: 16.99,
    features: [
      { id: 'ai-video', feature: '3000mins / month' },
      { id: 'audiogram', feature: 'Unlimited' },
      { id: 'file-size', feature: '300MB and 30mins per file' },
      { id: 'watermark', feature: 'No watermark' },
      { id: 'extra-transcription', feature: '$2 / hour' },
      { id: 'language', feature: '50+' },
      { id: 'resolution', feature: '1080 HD' },
      { id: 'stock', feature: 'Unlimited' }
    ],
    mobileFeatures: [
      'Up to 3000 minutes of AI video transcription each month',
      'No export limit for audiogram each month',
      'Up to 30 minutes and 300MB per AI Video and audiogram',
      'No watermark',
      '$2 / hour for additional transcription hours',
      '50+ languages supported',
      '1080 HD export',
      'Unlimited stock library'
    ],
    paymentInfo: paymentInfo['pro'],
    textColor: 'bg-gradient-blue-purple'
  }
];

const featuresList: FeatureData[] = [
  { id: 'ai-video', feature: 'AI Video transcription limit' },
  { id: 'audiogram', feature: 'Audiogram export limit' },
  { id: 'file-size', feature: 'File upload limit' },
  { id: 'watermark', feature: 'Watermark' },
  { id: 'extra-transcription', feature: 'Additional transcription' },
  { id: 'language', feature: 'Language Supported' },
  { id: 'resolution', feature: 'Export resolution' },
  { id: 'stock', feature: 'Stock library' }
];
const LINKS = {
  TERMS: `https://jupitrr.notion.site/Terms-of-Service-42977fa1c8ca4074a5b297534a997797`,
  FAQ: `https://jupitrr.notion.site/Frequently-Asked-Questions-FAQs-88870971db934c56a09bbda163d2371f`,
  WHATS_NEW: `https://jupitrr.notion.site/Changelog-44ea837a82304bf0b096265b13ceffee`,
  PRIVACY: `https://jupitrr.notion.site/Privacy-Policy-0f03c99eb41a4d5ba039c075e7d9345e`,
  ABOUT: `https://jupitrr.notion.site/About-Us-309dd51df0b84853b85baf15eb886529`
};

const MAX_QUESTION_LENGTH = 85; // 85 for all templates.

const MAX_FILE_SIZE_IN_MB = 300;
const MAX_FILE_DURATION_IN_MINS = 30;

const HIDDEN_NAVBAR_ROUTES = [
  { path: '/', exact: true, isHeaderHidden: false },
  { path: '/export-slideshow', exact: false, isHeaderHidden: true },
  { path: '/export', exact: false, isHeaderHidden: false },
  { path: '/login', exact: false, isHeaderHidden: true },
  { path: '/email-login', exact: false, isHeaderHidden: true },
  { path: '/signup', exact: false, isHeaderHidden: true },
  { path: '/email-signup', exact: false, isHeaderHidden: true },
  { path: '/blog', exact: false, isHeaderHidden: true },
  { path: '/pricing', exact: false, isHeaderHidden: true },
  { path: '/about-us', exact: false, isHeaderHidden: true },
  { path: '/change-log', exact: false, isHeaderHidden: true },
  { path: '/terms-and-conditions', exact: false, isHeaderHidden: true },
  { path: '/privacy-policy', exact: false, isHeaderHidden: true },
  { path: '/404', exact: false, isHeaderHidden: true },
  { path: '/guide', exact: false, isHeaderHidden: true },
  { path: '/set-profile', exact: false, isHeaderHidden: true },
  { path: '/set-slug', exact: false, isHeaderHidden: true },
  { path: '/tryout-call', exact: false, isHeaderHidden: true },
  { path: '/waitlist', exact: false, isHeaderHidden: true },
  { path: '/audiogram', exact: false, isHeaderHidden: true },
  { path: '/product', exact: false, isHeaderHidden: true },
  { path: '/create-slideshow', exact: false, isHeaderHidden: false },
  { path: '/generate-slideshow', exact: false, isHeaderHidden: false },
  { path: '/create', exact: false, isHeaderHidden: false },
  { path: '/generate', exact: false, isHeaderHidden: false }
];

export {
  API_URL,
  MEDIAS_LAMBDA_API_URL,
  PROXY_URL,
  paymentInfo,
  UPLOAD_BUCKET,
  IS_DEV_ENV,
  UNSPLASH_ACCESS_KEY,
  PEXELS_KEY_LIST,
  MIXPANEL_KEY,
  LINKS,
  plans,
  featuresList,
  VIDEO_ROUTE,
  MAX_QUESTION_LENGTH,
  MAX_FILE_DURATION_IN_MINS,
  MAX_FILE_SIZE_IN_MB,
  AI_PLANS,
  AUDIOGRAM_PLANS,
  PLANS,
  FREE_PLANS,
  DEMO_VIDEO_URL,
  HIDDEN_NAVBAR_ROUTES
};
export type { PlanData, PLAN_OPTIONS };
