import '../assets/css/customCarousel.scss';
import '../assets/css/customPlayer.scss';
import '../assets/css/tailwind.css';
import '../assets/css/textRollAnimation.scss';
import '../components/Shared/Carousel.css';
import '../components/Shared/ReactModal.css';
import '../styles/blog-page.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';

import { NextSeo } from 'next-seo';
import { OverlayProvider } from '../components/Blog/contexts/overlayProvider';
import { PageNames, detectWebView } from '../utils/generalUtilities';
import { PostHogProvider } from 'posthog-js/react';
import { RecoilRoot } from 'recoil';
import { SlideShowProvider } from '../contexts/SlideShowContext';
import { ThemeProvider } from '../components/Blog/contexts/themeProvider';
import { ToastContainer } from 'react-toastify';
import { UserProvider } from '../hooks/useUser';
import { metaTag } from '../assets/generalAssets';
import { processEnv } from '../lib/blog/processEnv';
import DashboardHeader from '@/components/Shared/DashboardHeader';
import DocumentHead, {
  MetaTagKeys,
  TWITTER_HANDLE,
  description,
  title
} from '../components/DocumentHead';
import Head from 'next/head';
import React from 'react';
import ReactHelmet from 'react-helmet';
import SideNavbar from '@/components/SideNavbar/SideNav';
import posthog from 'posthog-js';

const TOASTER_DELAY_IN_MS = 7000; // default 5 seconds, ie 5000ms

if (typeof window !== 'undefined') {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: '/ingest',
    ui_host: 'https://us.i.posthog.com',
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug(); // debug mode in development
    }
  });
}

const SeoStuff = () => {
  const isGhostPages =
    typeof window !== 'undefined' &&
    window.location.pathname.startsWith(PageNames.BLOG);
  const isWaitlist =
    typeof window !== 'undefined' &&
    window.location.pathname.includes('waitlist');
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=2.0, user-scalable=no"
        />
      </Head>
      {!isGhostPages ? (
        <NextSeo
          title={title}
          description={description}
          openGraph={{
            title: title,
            description: description,
            images: isWaitlist
              ? []
              : [
                  {
                    url: metaTag,
                    width: 1200,
                    height: 400
                  }
                ]
          }}
          twitter={{
            handle: TWITTER_HANDLE,
            site: TWITTER_HANDLE,
            cardType: MetaTagKeys.TWITTER_SUMMARY_LARGE_IMAGE
          }}
          additionalLinkTags={[
            {
              rel: 'icon',
              href: 'https://d3enyft5v5gx6r.cloudfront.net/website/SEO/favicon.png'
            }
          ]}
          additionalMetaTags={[
            {
              property: MetaTagKeys.OG_TYPE,
              content: 'website'
            }
          ]}
        />
      ) : null}
    </>
  );
};

export default class MyApp extends React.Component<
  { Component; pageProps },
  any
> {
  constructor(props) {
    super(props);
    this.state = { ready: false, link: [] }; // This one.
  }

  static async getInitialProps({ Component, ctx }) {
    return {
      pageProps: Component.getInitialProps
        ? await Component.getInitialProps(ctx)
        : {}
    };
  }

  async componentDidMount() {
    this.setState({ ready: true }); // This one.
    detectWebView();

    // @ts-ignore
    this.addHref(await import('../assets/css/global.scss'));
    this.resetLink();
    if (window.location.pathname !== '/') {
      // @ts-ignore
      this.addHref(await import('../assets/css/style.scss'));
    }
  }

  addHref(href: string) {
    const { link } = this.state;
    link.push({
      rel: 'stylesheet',
      type: 'text/css',
      href
    });
    this.setState({
      link: link
    });
  }

  resetLink() {
    this.setState({
      link: []
    });
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <>
        <PostHogProvider client={posthog}>
          {this.state.link !== null ? (
            <ReactHelmet link={this.state.link} />
          ) : null}
          <SeoStuff />
          <DocumentHead />
          <React.Fragment>
            <ToastContainer
              icon={false}
              theme="light"
              limit={3}
              autoClose={TOASTER_DELAY_IN_MS}
            />
            <div
              className={`${
                this.state.ready ? 'relative flex flex-col' : 'hidden'
              } h-screen overflow-x-hidden`}
            >
              <ThemeProvider {...processEnv.darkMode}>
                <RecoilRoot>
                  <OverlayProvider>
                    <UserProvider>
                      <SlideShowProvider>
                        <DashboardHeader />
                        <div className="flex h-full w-screen flex-row overflow-hidden">
                          <SideNavbar />
                          <div className="w-full overflow-scroll">
                            <Component {...pageProps} />
                          </div>
                        </div>
                      </SlideShowProvider>
                    </UserProvider>
                  </OverlayProvider>
                </RecoilRoot>
              </ThemeProvider>
            </div>
            {/* Go Top Button  */}
            {/*@ts-ignore*/}
            {/*<GoTop scrollStepInPx="50" delayInMs="16.66"/>*/}
          </React.Fragment>
        </PostHogProvider>
      </>
    );
  }
}
