import { ModalProvider } from '../../hooks/useModal';
import { NewJPButton, Size } from '../GeneralComponents';
import { PageNames } from '../../utils/generalUtilities';
import { WaitlistModal } from '../AiVideoHome/WaitlistModal';
import { useRouter } from 'next/router';
import { useState } from 'react';
import Image from 'next/image';
import Menu from '../Shared/Menu';
const mic = require('../../assets/images/landing/mic.svg');
const slideshow = require('../../assets/images/landing/slideshow.svg');

const CreateNewButton = ({
  withIcon,
  size
}: {
  withIcon?: boolean;
  size?: Size;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const router = useRouter();
  const updateModalOpen = (value: boolean) => {
    setIsModalOpen(value);
  };

  const menuOptions = [
    {
      title: 'AI Video',
      isVisible: true,
      image: (
        <Image
          className="mr-3"
          alt="slideshow"
          loading={'lazy'}
          src={slideshow}
        />
      ),
      onClick: () => router.push({ pathname: PageNames.CREATE_SLIDESHOW })
    },
    {
      title: 'Audiogram',
      isVisible: true,
      image: <Image className="mr-3" alt="mic" loading={'lazy'} src={mic} />,
      onClick: () => router.push({ pathname: PageNames.CREATE })
    }
  ];

  return (
    <div className="relative w-full">
      <NewJPButton
        size={size || 'lg'}
        text="Create new"
        icon={withIcon ? 'mi-add' : null}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="justify-center !px-5 !py-2.5"
      />
      {isMenuOpen ? (
        <Menu
          isMenuOpen={isMenuOpen}
          onLeave={() => setIsMenuOpen(false)}
          customContainerStyle={{
            top: 60,
            left: 0,
            width: 163
          }}
          menuOptions={menuOptions}
        />
      ) : null}

      {isModalOpen && (
        <ModalProvider>
          <WaitlistModal
            isModalOpen={isModalOpen}
            updateModalOpen={updateModalOpen}
          />
        </ModalProvider>
      )}
    </div>
  );
};

export default CreateNewButton;
