import { ExternalUrls, PageNames } from '../../utils/generalUtilities';
import { NewJPButton } from '../GeneralComponents';
import { P1 } from '../OldGeneralComponents';
import { PRICING_SOURCE } from '../../audiogram/utils/constant';
import { useRouter } from 'next/router';
import Link from 'next/link';
import locale from '../../locales/en/NativeLanding';

const MobileLinks = () => {
  const router = useRouter();
  return (
    <div className="relative w-full">
      <ul className="w-full flex-col items-center justify-start">
        <li className="mb-2">
          <a href={PageNames.BLOG}>
            <P1 className="py-3 text-grey-500">Blog</P1>
          </a>
        </li>
        <li className="mb-2">
          <Link href={`${PageNames.PRICING}?source=${PRICING_SOURCE.header}`}>
            <P1 className="py-3 text-grey-500">Pricing</P1>
          </Link>
        </li>
        <li className="mb-2">
          <Link href={ExternalUrls.AFFILIATE}>
            <P1 className="py-3 text-grey-500">Affiliate</P1>
          </Link>
        </li>
        <li className="mb-2">
          <Link
            passHref={true}
            target="_blank"
            href={`${PageNames.CHANGE_LOG}`}
          >
            <P1 className="py-3 text-grey-500">Changelog</P1>
          </Link>
        </li>
        <li className="mb-2">
          <a href="#" onClick={() => router.push(PageNames.LOGIN)}>
            <P1 className="py-3 text-grey-500">{locale.common.login}</P1>
          </a>
        </li>
      </ul>
      <NewJPButton
        className="mt-4 w-full"
        text={locale.common.sign_up}
        onClick={() => router.push(PageNames.SIGNUP)}
      />
    </div>
  );
};

export default MobileLinks;
