import { API_URL } from './constant';
import { FetchError } from '@giphy/js-fetch-api';
import { LocalStorageItems } from '@/utils/generalUtilities';
import fetchWrapper, { MethodType } from '@/utils/fetchWrapper';
import ffmpeg from '@/types/ffmpeg';

interface ValidateMediaTypeReqBody {
  url: string;
  metadata?: boolean;
}

interface ValidateMediaTypeResult {
  file_valid: boolean;
  error: undefined;
  duration: number | undefined;
  type: string;
  stderr?: string;
  data?: ffmpeg.FfprobeData;
}

const FFmpegService = {
  name: '/ffmpeg',

  validate(
    validateInput: ValidateMediaTypeReqBody
  ): Promise<ValidateMediaTypeResult & FetchError> {
    // UserInfo
    return new Promise((resolve, reject) => {
      let route = `${API_URL + this.name}/validate`;
      fetchWrapper(
        route,
        MethodType.POST,
        JSON.stringify(validateInput),
        localStorage.getItem(LocalStorageItems.ACCESS_TOKEN)
      )
        .then((json: any) => {
          resolve(json);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  }
};

export default FFmpegService;
