import {
  LocalStorageItems,
  getItem,
  hideNewNav
} from '@/utils/generalUtilities';
import { NewJPButton } from '../GeneralComponents';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useRecoilValueLoadable } from 'recoil';
import { useRouter } from 'next/router';
import { useUser } from '@/hooks/useUser';
import { userInfo } from '@/recoil/atoms/user.atoms';
import CreateNewButton from '../JPHeader/CreateNewButton';
import Icon, { IconType } from '../Shared/Icon';
import Link from 'next/link';
import Loading from '../Loading';
import NewPlanInformationPill from '../Profile/NewPlanInformationPill';
import React, { useEffect, useState } from 'react';
import User from '@/models/User';

function SideNavbar() {
  const userInfoLoadable = useRecoilValueLoadable(userInfo);
  const router = useRouter();
  const [initialized, setInitialized] = useState(false);
  const usernamePath = '/@' + getItem(LocalStorageItems.URL_SLUG);
  const navRoutesArray: {
    name: string;
    href: string;
    icon: IconType | `mi-${string}` | `ft-${string}`;
    isOutsideLink: boolean;
  }[] = [
    {
      name: 'Library',
      href: usernamePath,
      icon: 'ft-video',
      isOutsideLink: false
    },
    {
      name: 'Affiliate',
      href: 'https://affiliate.jupitrr.com/program',
      icon: 'ft-link',
      isOutsideLink: true
    },
    {
      name: 'FAQs',
      href: '/faq',
      icon: 'ft-book',
      isOutsideLink: false
    },
    {
      name: 'Settings',
      href: '/settings',
      icon: 'ft-settings',
      isOutsideLink: false
    }
  ];

  const { user } = useUser();
  const [loading, setLoading] = useState(true);
  const setRecoilUserInfo = useSetRecoilState(userInfo);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const accessToken = getItem(LocalStorageItems.ACCESS_TOKEN);
      if (accessToken && user?.id) {
        try {
          const fetchedUserInfo = await User.getUserInfo(user.id, accessToken);
          setRecoilUserInfo(fetchedUserInfo);
        } catch (error) {
          console.error('Error fetching user info:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [setRecoilUserInfo, user?.id]);
  useEffect(() => {
    const maxWaitTime = 3000;
    const timeoutId = setTimeout(() => {
      setInitialized(true);
    }, maxWaitTime);
    if (
      userInfoLoadable.state === 'hasValue' &&
      userInfoLoadable.contents !== null
    ) {
      setInitialized(true);
      clearTimeout(timeoutId);
    }
    return () => clearTimeout(timeoutId);
  }, [userInfoLoadable.state]);

  // Temporary condition to prevent the sidenav to render on homepage
  const { isNavHidden } = hideNewNav(router.pathname);
  if (isNavHidden) return <></>;
  if (loading)
    return (
      <div className="flex h-full items-center justify-center">
        <Loading size={24} />
      </div>
    );
  return (
    <>
      <div className="z-50 flex h-full w-[220px] flex-col items-center justify-between bg-white p-2">
        <div className="flex h-full w-full flex-1 flex-col gap-2">
          <CreateNewButton withIcon={true} size="md" />
          {navRoutesArray.map((route, index) => {
            const text = route.isOutsideLink ? (
              <span className="flex items-center">
                {route.name}{' '}
                <Icon
                  type="ft-external-link"
                  className="text-gray-400"
                  size={11}
                />
              </span>
            ) : (
              route.name
            );
            return (
              <Link
                href={route.href}
                target={route.isOutsideLink ? '_blank' : '_self'}
                key={index}
              >
                <NewJPButton
                  colorType="transparent"
                  className={`w-full  hover:text-gray-800 ${
                    router.pathname === route.href ||
                    ((router.pathname === '/[uid]' ||
                      router.pathname === '/me') &&
                      route.name === 'Library')
                      ? 'bg-gray-100 !text-gray-800'
                      : 'hover:bg-gray-200'
                  }`}
                  size="md"
                  text={text}
                  // onClick={() => router.push(route.href, '_blank')}
                  icon={route.icon}
                />
              </Link>
            );
          })}
        </div>
        <div>
          {!initialized || userInfoLoadable.state === 'loading' ? (
            <Loading size={24} />
          ) : userInfoLoadable.state === 'hasValue' ? (
            <NewPlanInformationPill user={userInfoLoadable.contents} />
          ) : (
            <div>Error loading user information</div>
          )}
        </div>
      </div>
    </>
  );
}

export default SideNavbar;
