import { PageNames } from '../../utils/generalUtilities';
import { ScreenTypes, useScreenType } from '../../hooks/useScreenType';
import { logoHorizontal, logoWithName } from '../../assets/generalAssets';
import { useRouter } from 'next/router';
import { useState } from 'react';
import Image from 'next/image';
const Logo = ({ withName }: { withName?: boolean }) => {
  const screenType = useScreenType();
  const router = useRouter();
  return (
    <div
      onClick={() => router.push(PageNames.ROOT)}
      className={`relative mr-[14px] flex h-[37px] cursor-pointer items-center justify-center ${
        screenType === ScreenTypes.tab
          ? 'mt-[1px] w-[110px]'
          : 'w-[37px] overflow-hidden'
      }`}
    >
      <Image
        priority
        onClick={() => router.push(PageNames.ROOT)}
        src={withName ? logoWithName : logoHorizontal}
        width={110}
        height={37}
        className={`h-[37px] w-[110px] object-cover object-left`}
        alt={'jupitrr logo'}
      />
    </div>
  );
};

export default Logo;
