import { CSSProperties, FC, useEffect, useState } from 'react';
import { children } from 'cheerio/lib/api/traversing';
import { tailwindToColorCode } from '../utils/colour';
import Icon, { IconType } from './Shared/Icon';
import styled, { css } from 'styled-components';
const JPNativeButton = styled.button`
  pointer-events: ${(props) => (props.isLoading ? 'none' : 'all')};
  flex-flow: ${(props) => (props.iconLeft ? 'row-reverse' : 'row')};
  &:hover {
    transform: ${(props) => (props.disabled ? 'scale(1)' : 'scale(1.05)')};
    color: ${(props) => props.textColor};
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  user-select: none;

  ${(props) =>
    props.elevated &&
    css`
      box-shadow: 0px 2px 30px rgba(174, 174, 174, 0.2);
    `}

  ${(props) =>
    props.width === 'fit' &&
    css`
      width: fit-content;
    `}
  ${(props) =>
    props.width === 'full' &&
    css`
      width: 100%;
      justify-content: center;
    `}
  ${(props) =>
    props.height === 'fit' &&
    css`
      align-self: center;
    `}
  ${(props) =>
    props.height === 'full' &&
    css`
      align-self: stretch;
    `}

  ${(props) =>
    props.unclickable &&
    css`
      &:hover {
        transform: scale(1);
        cursor: default;
      }
    `}

  ${(props) =>
    props.isLoading &&
    css`
      pointer-events: none;
    `}
`;

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

interface JPButtonProps {
  text?: string | JSX.Element;
  size?: Size;
  colorType?:
    | 'transparent'
    | 'white'
    | 'light-blue'
    | 'blue'
    | 'white-red'
    | 'white-blue'
    | 'lighter-white';
  onClick?: () => void;
  iconSize?: Size;
  icon?: IconType | `mi-${string}` | `ft-${string}`;
  iconRight?: IconType | `mi-${string}`;
  className?: string;
  link?: string;
  disabled?: boolean;
  id?: string;
  width?: 'full' | 'fit';
  height?: 'full' | 'fit';
  type?: 'button' | 'div' | 'submit';
}

const defaultButtonClasses =
  'appearance-none flex relative items-center rounded-lg border border-solid';

const getAutoGradientTextStyle = (style: React.CSSProperties) => {
  const gradientStyle = {
    ...style
  };

  if (style.color) {
    gradientStyle.WebkitTextFillColor = 'rgba(255, 255, 255, 0)';
    gradientStyle.WebkitBackgroundClip = 'text';
    gradientStyle.background = style.color;
  }

  return gradientStyle;
};

const getIconSize = (btnSize: Size) => {
  switch (btnSize) {
    case 'xs':
      return 0.75 * 16;
    case 'sm':
      return 0.875 * 16;
    case 'md':
      return 16;
    case 'lg':
      return 1.125 * 16;
    case 'xl':
      return 1.25 * 16;
    default:
      return 1.5 * 16;
  }
};

const getTextColor = (colorType: string) => {
  switch (colorType) {
    case 'lighter-white':
    case 'white': {
      return ' text-grey-700';
    }
    case 'transparent': {
      return ' text-grey-500';
    }
    case 'light-blue':
    case 'white-blue': {
      return ' text-blue-700';
    }
    case 'white-red': {
      return ' text-red-700';
    }
    case 'blue':
    default: {
      return 'text-white';
    }
  }
};
const getIconColor = (colorType: string) => {
  switch (colorType) {
    case 'lighter-white':
    case 'white': {
      return ' text-grey-700';
    }
    case 'transparent': {
      return ' text-grey-500';
    }
    case 'light-blue':
    case 'white-blue': {
      return ' text-blue-500';
    }
    case 'white-red': {
      return ' text-red-700';
    }
    case 'blue':
    default: {
      return ' text-white';
    }
  }
};

const NewJPButton = (props: JPButtonProps) => {
  const {
    text: children,
    colorType,
    link,
    onClick,
    size,
    disabled,
    icon,
    iconSize,
    iconRight,
    width,
    height,
    type = 'div',
    ...rest
  } = props;
  const [classNameForButton, setClassName] = useState(defaultButtonClasses);

  useEffect(() => {
    let colorClass = '',
      sizeClass = '';
    switch (colorType) {
      case 'light-blue': {
        colorClass = `${getTextColor(colorType)} bg-blue-100 border-blue-50`;
        break;
      }
      case 'lighter-white': {
        colorClass = `${getTextColor(colorType)} bg-white border-grey-200`;
        break;
      }
      case 'white': {
        colorClass = `${getTextColor(colorType)} bg-white border-grey-300`;
        break;
      }
      case 'transparent': {
        colorClass = `${getTextColor(colorType)} border-transparent`;
        break;
      }
      case 'white-blue': {
        colorClass = `${getTextColor(colorType)} bg-white border-grey-300`;
        break;
      }
      case 'white-red': {
        colorClass = `${getTextColor(colorType)} bg-white border-red-100`;
        break;
      }
      case 'blue':
      default: {
        colorClass = `${getTextColor(colorType)} bg-blue-600 border-blue-600`;
        break;
      }
    }
    switch (size) {
      case 'xs': {
        sizeClass = 'py-2.5 px-2 text-xs';
        break;
      }
      case 'sm': {
        sizeClass = 'py-2.5 px-4 text-sm ';
        break;
      }
      case 'md': {
        sizeClass = 'py-2.5 px-[18px] text-base md:text-base';
        break;
      }
      case 'lg': {
        sizeClass = 'py-4 px-[26px] text-base md:text-base';
        break;
      }
      case 'xl': {
        sizeClass = 'py-[16px] px-[26px] text-lg md:text-lg';
        break;
      }
      case '2xl':
      default: {
        sizeClass = 'py-4 px-[26px] text-lg md:text-lg';
        break;
      }
    }
    setClassName(
      `${defaultButtonClasses} ${colorClass} ${sizeClass} ${props.className} ${
        disabled ? 'pointer-events-none cursor-auto' : 'cursor-pointer'
      }`
    );
  }, [colorType, size, disabled, props.className]);
  // const iconColor = useMemo(
  //   () => (icon ? tailwindToColorCode(getTextColor(colorType)) : null),
  //   [colorType, icon]
  // );

  const JPButtonContent = () => (
    <>
      {icon ? (
        <div className={!children ? '' : size === 'xl' ? 'mr-3' : 'mr-2'}>
          <Icon
            type={icon}
            size={getIconSize(iconSize ?? size)}
            color={
              colorType ? tailwindToColorCode(getIconColor(colorType)) : '#fff'
            }
          />
        </div>
      ) : null}
      {children}
    </>
  );
  if (link) {
    return (
      <a href={link} onClick={onClick} {...rest}>
        <JPNativeButton
          width={width}
          height={height}
          className={classNameForButton}
          as={type === 'div' ? 'div' : 'button'}
          type={type === 'submit' ? 'submit' : 'button'}
        >
          <JPButtonContent />
        </JPNativeButton>
      </a>
    );
  }

  return (
    <JPNativeButton
      width={width}
      height={height}
      onClick={onClick}
      as={type === 'div' ? 'div' : 'button'}
      type={type === 'submit' ? 'submit' : 'button'}
      {...rest}
      className={classNameForButton}
    >
      <JPButtonContent />
    </JPNativeButton>
  );
};

interface BadgeProps {
  text: string | React.ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  colorType?: 'white' | 'light-blue' | 'blue' | 'white-blue' | 'amber';
  onClick?: () => void;
  icon?: string;
  iconLeft?: boolean;
  iconSize?: number;
  className?: string;
  link?: string;
}

const defaultBadgeClasses =
  'appearance-none flex relative items-center justify-center text-center';
const Badge = (props: BadgeProps) => {
  const {
    text,
    colorType,
    link,
    onClick,
    size,
    className: injectedClassName
  } = props;
  const [classNameForBadge, setClassName] = useState(defaultButtonClasses);
  useEffect(() => {
    let colorClass = '',
      sizeClass = '';
    switch (colorType) {
      case 'amber': {
        colorClass = 'bg-amber-100 text-amber-900';
        break;
      }
      case 'light-blue':
      default: {
        colorClass = 'bg-blue-50 text-blue-700';
        break;
      }
    }
    switch (size) {
      case 'xs': {
        sizeClass = 'text-xs rounded-full px-[10px] ';
        break;
      }
      case 'sm': {
        sizeClass = 'text-sm rounded-full px-3 py-[2px]';
        break;
      }
      case 'md':
      default: {
        sizeClass = 'rounded-full px-3 py-[6px]';
        break;
      }
    }
    setClassName(
      `${defaultBadgeClasses} ${colorClass} ${sizeClass} ${injectedClassName}`
    );
  }, [colorType, size, injectedClassName]);

  if (link) {
    return (
      <a href={link} onClick={onClick} {...props}>
        <div className={classNameForBadge}>{text}</div>
      </a>
    );
  }

  return (
    <div onClick={onClick} {...props} className={classNameForBadge}>
      {text}
    </div>
  );
};

const NewH2 = (props: {
  children: React.ReactNode | string;
  className?: string;
}) => {
  return (
    <h2
      className={`text-2xl font-medium text-grey-900 md:text-3xl md:text-4xl ${props.className}`}
    >
      {props.children}
    </h2>
  );
};
const NewH4 = (props: {
  children: React.ReactNode | string;
  className?: string;
}) => {
  return (
    <h4 className={`text-lg text-grey-500 md:text-xl ${props.className}`}>
      {props.children}
    </h4>
  );
};

interface ParagraphProps {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const NewP1: FC<ParagraphProps> = ({ children, className, style, onClick }) => (
  <div
    className={`text-sm font-700 text-grey-700 ${className}`}
    style={style ? getAutoGradientTextStyle(style) : undefined}
    onClick={onClick}
  >
    {children}
  </div>
);

const NewP2: FC<ParagraphProps> = ({ children, className, style, onClick }) => (
  <div
    className={`text-sm font-light leading-[18px] ${className}`}
    style={style ? getAutoGradientTextStyle(style) : undefined}
    onClick={onClick}
  >
    {children}
  </div>
);

const NewP3: FC<ParagraphProps> = ({ children, className, style, onClick }) => (
  <div
    className={`text-xs font-light  leading-[13px] ${className}`}
    style={style ? getAutoGradientTextStyle(style) : undefined}
    onClick={onClick}
  >
    {children}
  </div>
);

interface TextComponentProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const TextXL: FC<TextComponentProps> = ({
  children,
  className,
  style,
  onClick
}) => (
  <h3
    onClick={onClick}
    className={`${className} text-xl font-500 leading-6 md:leading-[25px]`}
    style={style ? getAutoGradientTextStyle(style) : undefined}
  >
    {children}
  </h3>
);

const Text2XL: FC<TextComponentProps> = ({
  children,
  className,
  style,
  onClick
}) => (
  <h2
    onClick={onClick}
    className={`text-xl font-400  leading-[26px]  text-gray-900 md:text-2xl md:leading-[30px] ${className} ]`}
    style={style ? getAutoGradientTextStyle(style) : undefined}
  >
    {children}
  </h2>
);

const Text4XL: FC<TextComponentProps> = ({
  children,
  className,
  style,
  onClick
}) => (
  <h1
    onClick={onClick}
    className={`text-2xl font-bold leading-[30px] text-grey-900 md:text-4xl md:leading-[46px]  ${className}`}
    style={style ? getAutoGradientTextStyle(style) : undefined}
  >
    {children}
  </h1>
);

const Text5XL: FC<TextComponentProps> = ({
  children,
  className,
  style,
  onClick
}) => (
  <h1
    onClick={onClick}
    className={`text-2xl font-bold leading-[30px] text-grey-900 md:text-5xl md:leading-[60px]  ${className}`}
    style={style ? getAutoGradientTextStyle(style) : undefined}
  >
    {children}
  </h1>
);

const Text6XL: FC<TextComponentProps> = ({
  children,
  className,
  style,
  onClick
}) => (
  <h1
    onClick={onClick}
    className={`text-xl font-bold leading-[30px] sm:text-4xl sm:leading-[42px]  md:text-6xl md:leading-[64px]  ${className} `}
    style={style ? getAutoGradientTextStyle(style) : undefined}
  >
    {children}
  </h1>
);

const NewInteractionContainer = ({
  maxWidth,
  children,
  className
}: {
  maxWidth?: number;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={`flex flex-col max-w-[${
        maxWidth ?? 400
      }px] items-center p-0 text-center  ${className}`}
    >
      {children}
    </div>
  );
};
export {
  NewJPButton,
  NewH2,
  NewH4,
  NewP1,
  NewP2,
  NewP3,
  Badge,
  getIconSize,
  getIconColor,
  TextXL,
  Text2XL,
  Text4XL,
  Text5XL,
  Text6XL,
  NewInteractionContainer
};
export type { JPButtonProps };
