import { Colours } from '../Colours';
import FeatherIcon from 'feather-icons-react';
import Image from 'next/image';
import React from 'react';

export interface StandardIconProps {
  fill?: string;
  size?: number;
}

// const googleIcon = require('./images/icons/googleIcon.svg');
// const emailIcon = require('./images/icons/emailIcon.svg');
// const pauseIcon = require('./images/icons/pauseIcon.svg');
// const playIcon = require('./images/icons/playIcon.svg');
// const waveformIcon = require('./images/icons/waveformIcon.svg');

// const chromeLogo = require('./images/icons/chromeLogo.svg');
// const safariLogo = require('./images/icons/safariLogo.svg');

// const editIcon = require('./images/icons/editIcon.svg');
// const shareIcon = require('./images/icons/shareIcon.svg');
// const closeButton = require('./images/icons/closeButton.svg');
// const copyIcon = require('./images/icons/copyIcon.svg');
const addGreyIcon = require('../../assets/images/icons/add__grey.svg');
const addWhiteIcon = require('../../assets/images/icons/add__white.svg');
const closeMediumGreyIcon = require('../../assets/images/icons/close__grey.svg');
const closeWhiteIcon = require('../../assets/images/icons/close__white.svg');
const addCircleDarkGrey = require('../../assets/images/icons/add__circle__dark_grey.svg');
const autographDarkGrey = require('../../assets/images/icons/autograph__dark_grey.svg');
const scheduleDarkGrey = require('../../assets/images/icons/schedule__dark_grey.svg');
import CapitalisationIcon from '../../assets/images/icons/capitalisationIcon.svg';
import CheckCircleIcon from '../../assets/images/icons/check-circle.svg';
import CrossIcon from '../../assets/images/icons/cross.svg';
import DropdownCloseIcon from '../../assets/images/icons/dropdown_close.svg';
import DropdownOpenIcon from '../../assets/images/icons/dropdown_open.svg';
import GIFIcon from '../../assets/images/icons/gif.svg';
import HamburgerIcon from '../../assets/images/icons/hamburger.svg';
import LightningIcon from '../../assets/images/icons/lightning.svg';
import LoadingIcon from '../../assets/images/icons/loading.svg';
import SearchIcon from '../../assets/images/icons/search.svg';
import ShareIcon from '../../assets/images/icons/share.svg';
import TickIcon from '../../assets/images/icons/check.svg';

// import TickIcon from '../../assets/images/icons/tick.svg';
const playArrowRoundedIcon = require('../../assets/images/icons/playArrowIcon.svg');
const linkedInIcon = require('../../assets/images/icons/social/linkedinIcon.svg');
const twitterIcon = require('../../assets/images/icons/social/twitterIcon.svg');
const instagramIcon = require('../../assets/images/icons/social/instagramIcon.svg');
const nextLightGrey = require('../../assets/images/icons/nextLightGrey.svg');
const tickBlue = require('../../assets/images/icons/tick__blue.svg');
const tickYellow = require('../../assets/images/icons/tick__yellow.svg');
const tickGrey = require('../../assets/images/icons/tick__grey.svg');
const crossIconLight = require('../../assets/images/landing/crossIcon.svg');

const stockFootageFeatureIcon = require('../../assets/images/icons/stockFootage_Icon.svg');
const sparklesIcon = require('../../assets/images/icons/sparklesImage_Icon.svg');
const gifFeatureIcon = require('../../assets/images/icons/gifFeature_Icon.svg');

export const microphoneWhiteIcon = require('../../assets/images/icons/microphone__white.svg');

export enum IconType {
  'PlayArrowRoundedIcon' = 'play_blue',
  'Hamburger' = 'hamburger',
  'Next' = 'next',
  'Add' = 'add',
  'AddCircle' = 'add_circle',
  'Autograph' = 'autograph',
  'Schedule' = 'schedule',
  'Microphone' = 'microphone',
  'Dropdown_Open' = 'dropdown_open',
  'Dropdown_Close' = 'dropdown_close',
  'LinkedIn' = 'linkedin',
  'Twitter' = 'twitter',
  'Instagram' = 'instagram',
  'OldCircleTick' = 'old_circle_tick',
  'CheckCircle' = 'check_circle',
  'Loading' = 'loading',
  'Tick' = 'tick',
  'Cross' = 'cross',
  'Lightning' = 'lightning',
  // 'Google' = 'google',
  // 'Email' = 'email',
  // 'Pause' = 'pause',
  // 'Play' = 'play',
  // 'Waveform' = 'waveform',
  // 'Edit' = 'edit',
  'Share' = 'share',
  'Close' = 'close',
  'Visuals' = 'visuals',
  'Subitles' = 'subtitles',
  'Capitalisation' = 'capitalisation',
  'CrossLight' = 'crosslight',
  'GIFDropdown' = 'gifdropdown',
  'SearchDropdown' = 'searchdropdown',
  'StockFootageFeatureIcon' = 'StockFootageFeatureIcon',
  'SparklesIcon' = 'SparklesIcon',
  'GIFFeatureIcon' = 'GIFFeatureIcon'
}

interface IconProps {
  type: string;
  size?: number;
  color?: string;
  className?: string;
  strokeWidth?: number;
}

const ICON_IS_TS_FILE = (type: string) => {
  if (type === IconType.CheckCircle) return true;
  if (type === IconType.Dropdown_Open) return true;
  if (type === IconType.Dropdown_Close) return true;
  if (type === IconType.Lightning) return true;
  if (type === IconType.OldCircleTick) return true;
  if (type === IconType.Loading) return true;
  if (type === IconType.Tick) return true;
  if (type === IconType.Hamburger) return true;
  if (type === IconType.Cross) return true;
  if (type === IconType.CrossLight) return true;
  if (type === IconType.Share) return true;
  if (type === IconType.GIFDropdown) return true;
  if (type === IconType.SearchDropdown) return true;
  return false;
};

const getImageSrc = (type: string, color: string) => {
  //TODO: Setup other icons
  if (type === IconType.PlayArrowRoundedIcon) return playArrowRoundedIcon;
  if (type === IconType.PlayArrowRoundedIcon) return playArrowRoundedIcon;
  if (type === IconType.AddCircle && color === Colours.DarkGrey)
    return addCircleDarkGrey;
  if (type === IconType.Autograph && color === Colours.DarkGrey)
    return autographDarkGrey;
  if (type === IconType.Schedule && color === Colours.DarkGrey)
    return scheduleDarkGrey;
  if (type === IconType.Add && color === Colours.White) return addWhiteIcon;
  if (type === IconType.Microphone && color === Colours.White)
    return microphoneWhiteIcon;
  if (type === IconType.Close && color === Colours.MediumGrey)
    return closeMediumGreyIcon;
  if (type === IconType.Close && color === Colours.White) return closeWhiteIcon;
  if (type === IconType.LinkedIn) return linkedInIcon;
  if (type === IconType.Twitter) return twitterIcon;
  if (type === IconType.Instagram) return instagramIcon;
  if (type === IconType.Next) return nextLightGrey;
  if (type === IconType.OldCircleTick && color === Colours.BrightYellow)
    return tickYellow;
  if (type === IconType.OldCircleTick && color === Colours.MediumGrey)
    return tickGrey;
  if (type === IconType.OldCircleTick) return tickBlue;
  if (type === IconType.Capitalisation) return CapitalisationIcon;
  if (type === IconType.CrossLight) return crossIconLight;
  if (type === IconType.StockFootageFeatureIcon) return stockFootageFeatureIcon;
  if (type === IconType.GIFFeatureIcon) return gifFeatureIcon;
  if (type === IconType.SparklesIcon) return sparklesIcon;
  return addGreyIcon;
};

const formatColor = (c: string) => {
  if (!c) return null;
  if (c.startsWith('#')) return c;
  if (c.startsWith('color-')) return `var(--${c})`;
  if (c.startsWith('text-')) return `var(--${c.replace('text-', 'color-')})`;
  if (c.startsWith('bg-')) return `var(--${c.replace('bg-', 'color-')})`;
  return c;
};

const Icon = ({
  type,
  size = 18,
  color,
  className,
  strokeWidth
}: IconProps) => {
  if (type.startsWith('mi-')) {
    return (
      <i
        className={`${type} ${className} `}
        style={
          color
            ? { color: formatColor(color), fontSize: size }
            : { fontSize: size }
        }
      />
    );
  }
  if (type.startsWith('ft-')) {
    return (
      <FeatherIcon
        className={`${type} ${className} `}
        strokeWidth={strokeWidth || 2}
        size={size}
        style={
          color
            ? { color: formatColor(color), fontSize: size }
            : { fontSize: size }
        }
        icon={type.split('ft-')[1]}
      />
    );
  }
  if (ICON_IS_TS_FILE(type)) {
    if (type === IconType.Hamburger) return <HamburgerIcon fill={color} />;
    if (type === IconType.CheckCircle) return <CheckCircleIcon fill={color} />;
    if (type === IconType.Dropdown_Open)
      return <DropdownOpenIcon fill={color} />;
    if (type === IconType.Dropdown_Close)
      return <DropdownCloseIcon fill={color} />;
    if (type === IconType.Tick) return <TickIcon size={size} fill={color} />;
    if (type === IconType.Lightning)
      return <LightningIcon size={size} fill={color} />;
    if (type === IconType.Share)
      return <ShareIcon size={size} fill={formatColor(color)} />;
    if (type === IconType.Loading)
      return <LoadingIcon size={size} fill={formatColor(color)} />;
    if (type === IconType.Cross) return <CrossIcon size={size} fill={color} />;
    if (type === IconType.GIFDropdown)
      return <GIFIcon size={size} fill={color} />;
    if (type === IconType.SearchDropdown)
      return <SearchIcon size={size} fill={color} />;
  }
  return (
    <Image
      className={`${className}`}
      src={getImageSrc(type, color)}
      alt={'icon'}
      width={size}
      height={size}
    />
  );
};

export default Icon;
