import { FPS } from '../Video';
import { Sequence, useCurrentFrame } from 'remotion';
import { TextOutput } from './TextSequence';
import { normalFontSize } from '../utils/constant';
import React from 'react';

export const BigTitleSequence: React.FC<{
  collatedSubs: TextOutput[][];
  color: string;
  style?: React.CSSProperties;
}> = ({ collatedSubs, color, style }) => {
  const frame = useCurrentFrame();
  return (
    <>
      {collatedSubs.map((subs, idx) => {
        const LIMIT = subs.length - 1;
        const fromInSeconds =
          subs[0].f !== undefined ? Number(subs[0].f) : subs[0].from;
        const toInSeconds =
          subs[LIMIT].t !== undefined ? Number(subs[LIMIT].t) : subs[LIMIT].to;
        const currentTimeInSeconds = frame / FPS;
        const durationInFrames = Math.round(
          (toInSeconds - fromInSeconds) * FPS
        );

        return (
          <Sequence
            key={idx}
            layout={'none'}
            from={Math.round(fromInSeconds * FPS) + 1}
            durationInFrames={durationInFrames}
          >
            <div style={Object.assign({ ...normalFontSize, color }, style)}>
              {subs.map((subtitle, index) => {
                const from =
                  subtitle.f !== undefined
                    ? Number(subtitle.f)
                    : Number(subtitle.from);
                const to =
                  subtitle.t !== undefined
                    ? Number(subtitle.t)
                    : Number(subtitle.to);
                const content =
                  subtitle.c !== undefined ? subtitle.c : subtitle.content;
                return (
                  <div
                    key={index}
                    style={{
                      display: 'inline',
                      opacity:
                        to >= currentTimeInSeconds &&
                        from <= currentTimeInSeconds
                          ? 1
                          : 0.4
                    }}
                  >
                    {`${content} `}
                  </div>
                );
              })}
            </div>
          </Sequence>
        );
      })}
    </>
  );
};
