import './fonts.module.css';
import { SubtitleOptions, SubtitleWordLimit, VideoTemplateFonts } from '../types';
import React from 'react';

const baseFontFamily =
  'CircularStd, CircularStd-Bold, Noto Sans JP, Noto Sans KR, Noto Sans TC, Noto Sans Telugu, sans-serif';

const FONT_FAMILY = (font?: VideoTemplateFonts) =>
  font && font.length && font !== VideoTemplateFonts['Circular Std']
    ? `${font}, ${baseFontFamily}`
    : `${baseFontFamily}`;

const fontFamilyLight =
  'CircularStd, CircularStd-Medium, Noto Sans JP, Noto Sans KR, Noto Sans TC, Noto Sans Telugu, sans-serif';

const FONT_FAMILY_LIGHT = (font?: VideoTemplateFonts) =>
  font && font.length && font !== VideoTemplateFonts['Circular Std']
    ? `${font}, ${fontFamilyLight}`
    : `${fontFamilyLight}`;

const JUPITRR_HANDLE = '@jupitrr_ai';

const normalFontSize: React.CSSProperties = {
  lineHeight: '1.2em',
  fontSize: 64
};

const smallerFontSize: React.CSSProperties = {
  lineHeight: '1.3em',
  fontSize: 64
};

const PRICING_SOURCE = {
  header: 'header',
  footer: 'footer',
  dashboard: 'profile-dashboard-page',
  'settings-page': 'settings-page',
  'single-video-length-modal': 'single-video-length-modal',
  'remove-watermark': 'remove-watermark-modal',
  'monthly-total-video-limit-modal': 'monthly-total-video-limit-modal'
};

const EMPTY_AUDIO =
  'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/empty.mp3';

const SLIDESHOW_FILLER_SECONDS = 0.25;

const DEFAULT_SUBTITLES_STYLES_OPTIONS = {
  fontFamily: VideoTemplateFonts.PoetsenOne,
  subtitleType: SubtitleOptions.REELS,
  color: '#FDE047',
  capitalization: false,
  primaryColor: '#FFFFFF',
  wordLimit: SubtitleWordLimit.MORE,
  positionY: 20
}

const LANGUAGE_NOTO_FONT_MAPPING = {
  'default': 'Noto Sans/Serif',  // For most Latin script languages
  'specific': {
    'Arabic': 'Noto Naskh Arabic',
    'Armenian': 'Noto Sans Armenian',
    'Chinese': 'Noto Sans TC',  // For both Simplified & Traditional Chinese
    'Hebrew': 'Noto Sans Hebrew',
    'Hindi': 'Noto Sans Devanagari',
    'Korean': 'Noto Sans KR',
    'Marathi': 'Noto Sans Devanagari',
    'Nepali': 'Noto Sans Devanagari',
    'Japanese': 'Noto Sans JP',  // Japanese
    'Kannada': 'Noto Sans Kannada',
    'Urdu': 'Noto Nastaliq Urdu',
    'Tamil': 'Noto Sans Tamil',
    'Thai': 'Noto Sans Thai',
    'Vietnamese': 'Noto Sans Vietnamese'
  }
}

export {
  SLIDESHOW_FILLER_SECONDS,
  EMPTY_AUDIO,
  FONT_FAMILY,
  FONT_FAMILY_LIGHT,
  fontFamilyLight,
  JUPITRR_HANDLE,
  normalFontSize,
  smallerFontSize,
  PRICING_SOURCE,
  VideoTemplateFonts,
  DEFAULT_SUBTITLES_STYLES_OPTIONS,
  LANGUAGE_NOTO_FONT_MAPPING
};
